import React from 'react';

interface SafeLinkProps {
  href: string;
  className?: string;
}

export default ({ href, className = '', children } : React.PropsWithChildren<SafeLinkProps>) => (
  <a
    href={href}
    className={className}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </a>
);
