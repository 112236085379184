import React from 'react';

import loadable from '@loadable/component';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Sidebar from '../components/sidebar';
import AboutBox from '../components/about-box';
import LandingPage from '../components/landing-page/index';

const MainDisplay = loadable(() => import('../components/main-display'));

const IndexPage = () => (
  <Layout>
    <Helmet>
      <link rel="preconnect" href="https://cdn.iloveyouinfinity.com" />
    </Helmet>
    <SEO title="Home" />
    <LandingPage />
    <MainDisplay />
    <Sidebar />
    <AboutBox />
  </Layout>
);

export default IndexPage;
