import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import './share.scss';

const SHARE_COPY = 'Take part in an infinite sound experiment to help raise'
  + ' awareness about autism and love.';

const WINDOW_FEATURES = 'menubar=no,location=no,resizable=yes,scrollbars=yes,'
  + 'status=no,width=600,height=800';

const ShareURL = {
  FACEBOOK: 'https://www.facebook.com/share.php?u=',
  TWITTER: `https://twitter.com/intent/tweet?hashtags=iloveyouinfinityproject&text=${
    encodeURIComponent(SHARE_COPY)}&url=`,
};

const openShareWindow = (url: string, siteUrl: string) => {
  window.open(url + encodeURIComponent(siteUrl), 'SHARE', WINDOW_FEATURES);
};

interface ShareProps {
  visible: boolean;
}

const Share = ({ visible }: ShareProps) => {
  const { site: { siteMetadata: { siteUrl } } } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `,
  );
  return (
    <ul className={`share-bar${visible ? ' --visible' : ''}`}>
      <li>
        <a
          aria-label="Share on Facebook"
          className="button"
          href={ShareURL.FACEBOOK + encodeURIComponent(siteUrl)}
          onClick={(e) => {
            e.preventDefault();
            openShareWindow(ShareURL.FACEBOOK, siteUrl);
          }}
        >
          Facebook
        </a>
      </li>
      <li>
        <a
          aria-label="Share on Twitter"
          className="button"
          href={ShareURL.TWITTER + encodeURIComponent(siteUrl)}
          onClick={(e) => {
            e.preventDefault();
            openShareWindow(ShareURL.TWITTER, siteUrl);
          }}
        >
          Twitter
        </a>
      </li>
    </ul>
  );
};

export default Share;
