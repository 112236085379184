import React from 'react';
import { connect } from 'react-redux';
import './about-box.scss';
import { LogoXLink } from '../logo';
import SafeLink from '../safe-link';

export interface AboutBoxProps {
  isAboutOpen: boolean;
  toggleAbout: any;
}

const mapStateToProps = ({ isAboutOpen }: AboutBoxProps) => ({ isAboutOpen });

const mapDispatchToProps = (dispatch: any) => (
  { toggleAbout: () => dispatch({ type: 'TOGGLE_ABOUT' }) });

const AboutBox = ({ isAboutOpen, toggleAbout }: AboutBoxProps) => (
  <>
    <div
      className={`about-section-closer${isAboutOpen ? ' --visible' : ''}`}
      onClick={toggleAbout}
      onKeyDown={toggleAbout}
      role="button"
      tabIndex={0}
      aria-label="Close about section"
    />
    <article className={`about-section${isAboutOpen ? ' --visible' : ''}`}>
      <LogoXLink />
      <div className="copy">
        <section>
          <p>
            I Love You Infinity is an infinite audio experiment based on the
            unique way one autistic child expresses his love.
          </p>
          <p>
            The project launched on Global Autism Awareness Week 2020 to
            challenge damaging myths around autism and lack of emotion, and will
            continue to play forever.
          </p>
        </section>
        <section>
          <h3>Share Infinite Love</h3>
          <p>
            Share this website to help raise awareness of the spectrum of ways
            autistic minds express and experience love. Follow on Instagram and
            submit stories of love. Exhibit this audio experiment.
            {' '}
            <SafeLink href="https://www.autism.org.uk/get-involved/donate.aspx">
              Support the National Autistic Society
            </SafeLink>
            .
          </p>
        </section>
      </div>
      <SafeLink
        className="about-cta"
        href="https://www.instagram.com/iloveyouinfinityproject/"
      >
        <span>
          Follow
        </span>
      </SafeLink>
      <div className="credits">
        <p>Credits</p>
        <p>
          <SafeLink href="https://thankyouforlookingatmybook.com">
            Lisa Berenson
          </SafeLink>
          <br />
          <SafeLink href="http://www.orianagaeta.com/">
            Oriana Gaeta
          </SafeLink>
          <br />
          <SafeLink href="https://goodpraxis.coop">
            Good Praxis
          </SafeLink>
        </p>
      </div>
    </article>
  </>
);

export default connect(mapStateToProps, mapDispatchToProps)(AboutBox);
