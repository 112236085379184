import React from 'react';
import loadable from '@loadable/component';

import { connect } from 'react-redux';
import Logo from '../logo';

import './landing-page.scss';

const LaunchButton = loadable(() => import('../launch-button'));


interface LandingPageProps {
  isExperienceLaunched: boolean;
}

const LandingPage = ({ isExperienceLaunched }: LandingPageProps) => (
  <div className={`landing-page${isExperienceLaunched ? ' --hidden' : ''}`}>
    <Logo />
    <div className="launch-bottom-area">
      <LaunchButton />
      <p className="cta">
        Launch experiment with sound on
      </p>
    </div>
  </div>
);

const mapStateToProps = (
  { isExperienceLaunched }: LandingPageProps,
) => ({ isExperienceLaunched });

export default connect(mapStateToProps)(LandingPage);
