import React from 'react';
import { connect } from 'react-redux';

import './sidebar.scss';
import Share from '../share';

interface SideBarProps {
  isAboutOpen: boolean;
  isShareOpen: boolean;
  toggleAbout: () => {};
  toggleShare: () => {}
}

const SideBar = ({
  toggleAbout, toggleShare, isAboutOpen, isShareOpen,
} : SideBarProps) => (
  <nav className={`side-bar${isAboutOpen ? ' --open' : ''}`}>
    <button type="button" className="button" onClick={toggleAbout}>
      { isAboutOpen ? 'Close' : 'About' }
    </button>
    <button type="button" className="button" onClick={toggleShare}>Share</button>
    <Share visible={isShareOpen} />
  </nav>
);

const mapStateToProps = ({ isAboutOpen, isShareOpen }: SideBarProps) => (
  { isAboutOpen, isShareOpen }
);

const mapDispatchToProps = (dispatch: (action: { type: string }) => {}) => (
  {
    toggleAbout: () => dispatch({ type: 'TOGGLE_ABOUT' }),
    toggleShare: () => dispatch({ type: 'TOGGLE_SHARE' }),
  });

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
