import React from 'react';

import './logo.scss';

interface logoXLinkProps {
  className?: string;
}

export const LogoXLink = ({ className = 'infinity-logo' } : logoXLinkProps) => (
  <svg
    aria-label="I Love You Infinity logo"
    className={className}
    height="101"
    role="img"
    viewBox="0 0 230 101"
    width="230"
  >
    <use xlinkHref="#infinity-logo" />
  </svg>
);

export default ({ className = 'infinity-logo' } : logoXLinkProps) => (
  <svg
    aria-label="I Love You Infinity logo"
    className={className}
    role="img"
    viewBox="0 0 230 101"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="infinity-logo">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48
  41H35V0h13v41zM6
  42h14V2H6v40zm178.7
  31.4c0
  4.6-3.6
  8.4-8
  8.4s-8-3.7-8-8.3c0-4.7
  3.6-8.5
  8.1-8.5
  4.4.1
  8
  3.9
  7.9
  8.4zm36
  17.3a19.5
  19.5
  0
  01-22
  2.9
  21.2
  21.2
  0
  01-12.6-19.3c-.3-5.6-2.8-9.3-7.3-10.4-4.5-1-9
  1.4-10.7
  5.7a10
  10
  0
  003.6
  12.1
  9
  9
  0
  0012-1.9c.5-.5
  1-1
  1.3-1.8l.8
  2.7-1.4
  1.4a11
  11
  0
  01-13.9
  1.2
  12
  12
  0
  01-4.5-13.9c1.7-5
  6.8-8.3
  11.9-7.7
  5.2.6
  9.3
  4.7
  10
  10.2l.3
  2.3c.2
  2
  .4
  3.9
  1
  5.7
  2.9
  9.4
  12.3
  15
  21.7
  13.3a20
  20
  0
  0015.8-20.8
  19.8
  19.8
  0
  00-17.1-18.9
  19
  19
  0
  00-15.3
  5l-.4-.4-.9-1c3.2-3
  7-4.9
  11.1-5.5A21.1
  21.1
  0
  01227
  64.8c3.8
  9
  1.2
  19.7-6.4
  25.9zm-21.3-17.3c0-4.6
  3.7-8.4
  8-8.4
  4.5.1
  8
  3.9
  8
  8.5
  0
  4.5-3.6
  8.3-8
  8.3s-8-3.8-8-8.4zM214
  62.2c-4.6-3-10.7-2.5-14.9
  1.2l-1
  .8-.4.4-.6-1.3-.2-.6c.5-.6.7-.7
  1.3-1.2a14.3
  14.3
  0
  0117.7-.3c5.4
  4
  7.5
  11.3
  5
  17.7a14.7
  14.7
  0
  01-15.4
  9.7A15
  15
  0
  01193
  76l-.4-2.8c0-1.5-.2-3-.6-4.5-2.2-8-9.6-12.8-17.7-11.7-7.4
  1-13.4
  8-13.6
  15.9a16.5
  16.5
  0
  0013
  16.9
  15.2
  15.2
  0
  0013.5-3.7l.7-.6.5.8.4.7c-3.8
  4.6-11.7
  6.4-17.9
  4.1a18.9
  18.9
  0
  01-11.8-21.5C161
  60
  169.4
  53.8
  179
  55c8.7
  1
  15.5
  9
  15.7
  18.3
  0
  7
  4.7
  12.6
  11
  13.3
  6.5.8
  12.3-3.3
  14-9.8
  1.4-5.5-.9-11.4-5.6-14.5zm-39.9
  24.2c3.8.8
  7.3-.1
  10.4-2.6l1-1
  .7-.6.8
  1.8c-4.6
  5.8-14
  6.2-19.9
  1a15.7
  15.7
  0
  01-3.6-18.5c3-6.2
  9.7-9.4
  16.3-8a15.2
  15.2
  0
  0111.5
  14.8c.2
  8.4
  5.4
  15.2
  13.2
  16.5a15
  15
  0
  0015.6-6.5c4-5.6
  4.4-11.7
  1.2-17.8-3-5.9-8.1-8.8-14.6-8.7-4
  .1-7.5
  1.8-10.6
  4.6l-1-1.6c2.7-2.7
  5.9-4.3
  9.5-4.9a18.3
  18.3
  0
  0120.7
  18.2c.2
  9.4-7
  18-15.9
  18.9-9.6
  1-18.1-5.5-19.8-15.1-.2-1.2-.3-2.3-.3-3.5-.2-7-5.2-12.7-11.6-13.1a12.8
  12.8
  0
  00-13.6
  11c-1
  7.3
  3.2
  13.6
  10
  15zM162.7
  90a21.6
  21.6
  0
  01-6.5-22.6A20.7
  20.7
  0
  01173
  51.6c12.4-2.5
  24.5
  7.6
  24.9
  20.8.1
  3.9
  1.2
  7.1
  4.5
  9.3a9.2
  9.2
  0
  0012.9-3
  10
  10
  0
  00-2.4-13.1
  9.1
  9.1
  0
  00-13.4
  2.6
  9
  9
  0
  01-.4.7l-.8-3
  .6-.5.8-.7a11
  11
  0
  0114-1.1
  12
  12
  0
  014.3
  14
  11.4
  11.4
  0
  01-11.8
  7.6c-5.3-.7-9.4-4.8-10-10.3l-.2-2.2-.4-3.5c-2-9.7-11.1-16.8-20.2-15.8a19.8
  19.8
  0
  00-18
  20c0
  10.2
  7.3
  18.8
  17
  20
  5.6.6
  10.1-.8
  15.3-5l.5.4v.1l.1.1.2.3.4.4a20.4
  20.4
  0
  01-28.2.4zm62.6-31c-3.4-4.7-8-7.6-13.5-8.7A22
  22
  0
  00192
  56a22.2
  22.2
  0
  00-32.1
  1.7c-7.8
  9-7.7
  22.8
  0
  31.6a22.2
  22.2
  0
  0032
  1.6c11.1
  9.6
  24.7
  6.7
  32.2-1.5
  7.3-8.3
  8-21.2
  1.2-30.3zM84.7
  28.6c0-4.7-4-7.7-10.1-7.7-6.2
  0-10.1
  3-10.1
  7.7
  0
  4.6
  4
  7.6
  10
  7.6
  6.2
  0
  10.2-3
  10.2-7.6zm-33.6
  0C51
  19.2
  60.4
  13
  74.6
  13S98
  19.2
  98
  28.6c0
  9.3-9.3
  15.4-23.5
  15.4S51
  38
  51
  28.6zM110.4
  13H96L112
  43h17.2l16-30H131l-10.2
  21.6L110.3
  13zM175.7
  26c-.1-3.3-4-5.6-8.7-5.6-4.8
  0-8.5
  2.3-9
  5.6h17.7zm-31.5
  2.6c0-9.1
  9.4-15.5
  23-15.5
  13.2
  0
  22
  6.2
  22
  15.6
  0
  .8-.1
  2-.4
  3h-30.5c1.5
  3.4
  6.6
  4.8
  12
  4.8
  5.8
  0
  11.7-1.3
  14.7-3l3.6
  6.3a39
  39
  0
  01-19.2
  4.3c-15
  0-25.2-6-25.2-15.5zM4.2
  86.6L0
  95.1c4.6
  2.7
  13
  5
  21
  5
  16.4
  0
  27-7
  27-18v-23H34.7V71c0
  5.4-3.7
  9-9.2
  9-4.7
  0-7.9-3.3-7.9-8.2V59.1H4v14.1c0
  8.4
  6.2
  14.4
  16
  14.4
  7
  0
  12.7-3.2
  14.5-8.1v2c0
  5.7-5.6
  9.3-14.4
  9.3-5.4
  0-11.6-1.9-16-4.2zm81.5-14c0-4.6-4-7.7-10.1-7.7-6.2
  0-10.1
  3-10.1
  7.7
  0
  4.6
  4
  7.7
  10
  7.7
  6.2
  0
  10.2-3
  10.2-7.7zm-33.6
  0c0-9.4
  9.3-15.5
  23.5-15.5S99
  63.2
  99
  72.6s-9.3
  15.5-23.5
  15.5S52
  82
  52
  72.6zm48-14.5v16.4c0
  8.4
  5.9
  13.6
  15.2
  13.6
  7.4
  0
  13-3.2
  15.7-8l1.5
  7h11.7v-29h-13.5v13.6c0
  5.5-3.5
  8.8-9.2
  8.8-5
  0-8-2.8-8-7.4v-15h-13.3z"
        fill="currentColor"
      />

    </g>
  </svg>
);
